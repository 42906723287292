<template>
    <div class="step-wrap">
        <slot name="transition"></slot>
        <div class="step" v-bind:style="inlineStyles" @click="selectStep(step.id)" :class="{ selected: selectedStep == step.id }">
            {{ step.label === "###HIDE###" ? "" : step.label }}<br />
            <div class="events">
                <div class="event" :title="event.label" :class="{ selected: selectedEvent == event.id }" @click.stop.prevent="selectEvent(event)" v-for="event in step.events" v-bind:key="event.id">
                    <i class="fal fa-envelope"></i>
                </div>
            </div>
        </div>

        <div class="next-steps" :class="{ multiple: step.transitions && step.transitions.length > 1 }">
            <process-step
                v-for="t in step.transitions"
                v-bind:key="t.id"
                :step="t.next"
                :selStep="selectedStep"
                :selEvent="selectedEvent"
                @select-event="selectEvent($event)"
                @select-step="selectStep($event)"
            >
                <template v-slot:transition>
                    <div class="transition" :class="{ 'with-label': t.label != '', 'with-event': t.email !== null }">
                        {{ t.label }}
                        <div v-if="t.email" @click.stop.prevent="selectEvent(Object.assign(t.email, { label: t.label }))" class="event" :class="{ selected: selectedEvent == t.email.id }">
                            <i class="fal fa-envelope"></i>
                        </div>
                    </div>
                </template>
            </process-step>
        </div>
    </div>
</template>

<script>
export default {
    name: "process-step",
    props: ["step", "selStep", "selEvent"],
    data() {
        return {
            selectedStep: "",
            selectedEvent: ""
        };
    },
    computed: {
        inlineStyles() {
            if (this.step.events !== null && this.step.events.length > 0) {
                return { "min-width": this.step.events.length * 35 + 7 + "px" };
            }
            if (this.step.label === "###HIDE###") {
                return { padding: 0, border: 0 };
            }

            return {};
        }
    },
    methods: {
        selectStep(id) {
            this.selectedStep = id;
            this.$emit("select-step", id);
            if (id !== "") {
                this.selectedEvent = "";
                this.$emit("select-event", { id: "" });
            }
        },
        selectEvent(event) {
            this.selectedEvent = event.id;
            this.$emit("select-event", event);
            if (event.id !== "") {
                this.selectedStep = "";
                this.$emit("select-step", "");
            }
        }
    },
    watch: {
        selStep: {
            handler: function() {
                this.selectedStep = this.selStep;
            },
            immediate: true
        },
        selEvent: {
            handler: function() {
                this.selectedEvent = this.selEvent;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
div.step {
    border: 1px solid #e2dee6;
    border-radius: 0.5rem;
    display: flex;
    padding: 1rem;
    height: 5rem;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:hover {
        border-color: darken(#e2dee6, 25%);
    }

    &.selected {
        border-color: #ff6600;
        color: #ff6600;
    }

    div.events {
        display: flex;
        position: absolute;
        bottom: -1rem;
        right: 0;

        div.event {
            margin-right: 0.5rem;
        }
    }
}

div.event {
    text-align: center;
    line-height: 2rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid #e2dee6;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
    color: #7b6b8d;

    &:hover {
        border-color: darken(#e2dee6, 25%);
    }

    &.selected {
        border-color: #ff6600;
        color: #ff6600;
    }
}

div.step-wrap {
    display: flex;
}

div.next-steps {
    position: relative;
    display: flex;

    &.multiple {
        flex-direction: column;

        > div.step-wrap {
            margin-left: 2rem;
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: -2rem;
                border: 0 solid #e2dee6;
                width: 2rem;
            }

            &:first-child:before {
                top: 2.5rem;
                border-top-width: 1px;
                border-right-width: 1px;
                height: calc(100% - 2.5rem);
            }

            &:not(:first-child):before {
                top: -1rem;
                border-right-width: 1px;
                width: 2rem;
                height: 6rem;
            }

            &:last-child:before {
                height: 3.5rem;
            }
        }
    }

    > div.step-wrap:not(:first-child) {
        margin-top: 1rem;
    }
}

div.transition {
    border: 0 solid #e2dee6;
    border-top-width: 1px;
    min-width: 2rem;
    text-align: center;
    margin-top: 2.5rem;
    padding-top: 0.5rem;
    position: relative;

    &.with-label {
        width: 10rem;
    }

    &.with-event {
        padding-top: 1rem;
    }

    div.event {
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
